var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { alignItems, backgroundColor, borderRadius, boxShadow, display, height, justifyContent, minWidth, opacity, space, size as styledSize, themeGet, width, } from 'styled-system';
import { useMatchesViewport } from '../../../common/hooks/media';
import { useWishlist } from '../../hooks/wishlist';
import { useLocalization } from '../../providers/LocaleProvider';
import { pulse } from '../../theme/keyframes';
import { cursor, outline, themeOpacity, transform } from '../../theme/system-utilities';
import { Button } from '../Button';
import Icon from '../Icon';
import { LinkRegularLR3 } from '../Typography';
import { formatWishlistCount } from './utils';
var IconColor;
(function (IconColor) {
    IconColor["Inactive"] = "colors.sys.neutral.icon.default";
    IconColor["Hover"] = "colors.sys.primary.icon.hover";
    IconColor["Active"] = "colors.sys.primary.icon.active";
})(IconColor || (IconColor = {}));
var ICON_SIZE = [16, null, null, 24];
var iconAnimationCSS = function (_a) {
    var isActive = _a.isActive;
    return isActive && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    animation: ", " 1s ease;\n  "], ["\n    animation: ", " 1s ease;\n  "])), pulse);
};
var ButtonHitArea = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), space, cursor);
var buttonHitAreaStyles = {
    cursor: 'pointer',
};
var ButtonContainer = styled(Button)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", "\n  ", ";\n  ", ";\n  ", ";\n\n  &:focus-visible {\n    outline: 2px solid ", ";\n  }\n"])), display, width, height, borderRadius, backgroundColor, themeOpacity, outline, alignItems, boxShadow, minWidth, justifyContent, themeGet('colors.sys.secondary.border.focus'));
var buttonContainerStyles = {
    display: 'flex',
    kind: 'bare',
    alignItems: 'center',
    bg: 'sys.neutral.background.default',
    borderRadius: 'x-large',
    cursor: 'pointer',
    p: ['sp_8', null, null, 'sp_12'],
    themeOpacity: 'strong',
    outline: 'none',
    boxShadow: 'low',
    height: 'sp_40',
    minWidth: 'sp_40',
    justifyContent: 'center',
};
var StyledIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  fill: ", ";\n  &:hover {\n    fill: ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  fill: ", ";\n  &:hover {\n    fill: ", ";\n  }\n"])), styledSize, iconAnimationCSS, function (_a) {
    var isActive = _a.isActive;
    return themeGet(isActive ? IconColor.Active : IconColor.Inactive);
}, themeGet(IconColor.Hover));
StyledIcon.defaultProps = {
    size: ICON_SIZE,
};
var Count = styled(LinkRegularLR3)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n\n  animation: slideIn 0.5s forwards;\n\n  @keyframes slideIn {\n    to {\n      opacity: 1;\n      transform: translateX(0);\n    }\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n\n  animation: slideIn 0.5s forwards;\n\n  @keyframes slideIn {\n    to {\n      opacity: 1;\n      transform: translateX(0);\n    }\n  }\n"])), opacity, transform, cursor);
var countStyles = {
    as: 'label',
    color: 'sys.neutral.text.default',
    pl: ['sp_8', null, null, 'sp_12'],
    transform: 'translateX(100%)',
    cursor: 'pointer',
};
var useGetIcon = function (isActive) {
    var matchesLargeDesktop = useMatchesViewport().matches.matchesLargeDesktop;
    if (matchesLargeDesktop) {
        return isActive ? 'wishlistV2FilledLarge' : 'wishlistV2Large';
    }
    return isActive ? 'wishlistV2Filled' : 'wishlistV2';
};
var NewWishlistButton = function (_a) {
    var sku = _a.sku, tracking = _a.tracking, index = _a.index, _b = _a.size, size = _b === void 0 ? 17 : _b, list = _a.list, _c = _a.count, count = _c === void 0 ? 0 : _c, props = __rest(_a, ["sku", "tracking", "index", "size", "list", "count"]);
    var t = useLocalization().t;
    var buttonRef = useRef(null);
    var _d = __read(useState(count), 2), localCount = _d[0], setLocalCount = _d[1];
    var _e = useWishlist({
        sku: sku,
        tracking: tracking,
        position: index,
        list: list,
        onFinished: function () {
            var _a;
            setLocalCount(function (prevCount) { return (isWishlisted ? prevCount - 1 : prevCount + 1); });
            (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        },
    }), isWishlisted = _e.isWishlisted, isInProgress = _e.isInProgress, clickHandler = _e.clickHandler;
    var iconName = useGetIcon(isWishlisted);
    return (React.createElement(ButtonHitArea, __assign({}, buttonHitAreaStyles, { onClick: clickHandler }),
        React.createElement(ButtonContainer, __assign({ ref: buttonRef, "data-testid": "wishlist-button", "aria-label": isWishlisted ? t('h24_remove_from_wishlist') : t('h24_add_to_wishlist'), role: "checkbox", disabled: isInProgress, "aria-checked": isWishlisted }, buttonContainerStyles, props, (props.height && !localCount && { width: props.height, p: 0 })),
            React.createElement(StyledIcon, __assign({ name: iconName, dataTestId: iconName, isActive: isWishlisted }, (size && { size: size }))),
            localCount ? (React.createElement(Count, __assign({ "data-testid": "wishlist-count" }, countStyles), formatWishlistCount(localCount))) : null)));
};
export default NewWishlistButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
