export var formatWishlistCount = function (count) {
    if (count < 1000) {
        return count.toString();
    }
    var UNITS = ['', 'k', 'M'];
    var unitIndex = 0;
    var num = count;
    while (num >= 1000 && unitIndex < UNITS.length - 1) {
        num = Math.floor(num / 100) / 10;
        unitIndex++;
    }
    return "".concat(num.toFixed(1).replace('.', ',').replace(/,0$/, '')).concat(UNITS[unitIndex]);
};
